import { ActionTypeEnum } from 'types/enums.ts'
import { useFetcher } from '@remix-run/react'
import { Icon } from '~/components/ui/icons/icon.tsx'

interface ActionReorderElementProps {
  serviceId: number
  actionType: ActionTypeEnum
}

export const ActionReorderElement = ({
  serviceId,
  actionType,
}: ActionReorderElementProps) => {
  const fetcher = useFetcher()

  const handleOrderChange = (type: ActionTypeEnum) => {
    fetcher.submit({ type, id: serviceId }, { method: 'POST' })
  }

  return (
    <fetcher.Form method="POST" className="flex justify-center">
      <input type="hidden" name="type" value={actionType} />
      <input type="hidden" name="id" value={serviceId} />
      <Icon
        name={'chevron-down'}
        size="lg"
        className={`cursor-pointer text-secondary ${
          actionType === ActionTypeEnum.orderUp && 'rotate-180'
        }`}
        onClick={event => {
          event.preventDefault()
          handleOrderChange(actionType)
        }}
      />
    </fetcher.Form>
  )
}
