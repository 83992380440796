import { type Currency, type Service, type User } from '@prisma/client'
import { cn } from '~/utils/misc.tsx'
import { Badge, getBadgeVariantByStatus } from '../ui/badge.tsx'
import { Icon } from '../ui/icons/icon.tsx'
import { ButtonTrigger } from '~/components/ui/inputs/button/Button.tsx'
import { ScrollArea } from '../ui/scroll-area.tsx'
import { Link } from '@remix-run/react'
import { type UserStatusType } from '~/models/user.ts'
import { ActionTypeEnum, StatusEnum } from '../../../types/enums.ts'
import { ActionDelete } from '~/features/admin/action-delete.tsx'
import { ActionEnableDisable } from '~/features/admin/action-enable-disable.tsx'
import { Price } from '../price.tsx'
import { ActionReorderElement } from '~/features/admin/action-reorder-element.tsx'

const ServiceCardPrivate = ({
  className,
  service,
  currency,
  position,
}: {
  className?: string
  service: Service
  currency: {
    name: Currency['name']
    value: Currency['value']
  }
  position?: {
    isFirst: boolean
    isLast: boolean
  }
}) => {
  const { badgeVariant, statusText } = getBadgeVariantByStatus(
    service.status as UserStatusType,
  )

  return (
    <div
      className={cn(
        'flex w-full flex-col  gap-4 rounded-lg  border-2 border-gray-300 p-4',
        className,
      )}
    >
      <div className="flex justify-between gap-4">
        <Badge
          className="w-fit rounded-sm text-sm"
          variant={badgeVariant as any}
        >
          {statusText}
        </Badge>

        <div className="flex gap-2">
          {position && !position.isFirst && (
            <ActionReorderElement
              serviceId={service.id}
              actionType={ActionTypeEnum.orderUp}
            />
          )}

          {position && !position.isLast && (
            <ActionReorderElement
              serviceId={service.id}
              actionType={ActionTypeEnum.orderDown}
            />
          )}

          <Link
            to={
              service?.specialService
                ? `/admin/special-services/${service.id}/edit`
                : `/professional/services/${service.id}/edit`
            }
          >
            <ButtonTrigger variant={'ghost'} size={'xs'} className="gap-1">
              <Icon name="pencil" className="cursor-pointer text-accent-300" />
              Editar
            </ButtonTrigger>
          </Link>
        </div>
      </div>
      <div className="text-h5 font-bold">{service.name}</div>
      <div className="flex gap-6">
        {service.specialService && (
          <Icon name="category" size="drawer" className="text-secondary">
            <span className="text-sm">{service.specialService}</span>
          </Icon>
        )}
        {service.isVideoCall && (
          <Icon name="clock" size="drawer" className="text-secondary">
            <span className="text-sm">{service.duration} minutos</span>
          </Icon>
        )}
        <Icon name="credit-card" size="drawer" className="text-secondary">
          <span className="text-sm">
            <Price
              amount={service.price * currency.value}
              currency={currency.name}
            />
          </span>
        </Icon>
      </div>
      <p className="text-secondary">Descripción del servicio</p>
      <p className="text-foreground-muted">{service.description}</p>
      <div className="mt-6 grid grid-cols-2 justify-between gap-3 sm:flex-row sm:gap-6">
        <ActionDelete
          id={service.id}
          description={`¿Confirma que desea remover el servicio "Consulta astrologica"?`}
          triggerClassName="w-full"
          trigger={
            <ButtonTrigger variant={'cancel'} className="w-full max-w-[250px]">
              Eliminar
            </ButtonTrigger>
          }
        />
        {service.status !== StatusEnum.PENDING && (
          <ActionEnableDisable
            id={service.id}
            status={service.status}
            statusCondition={{
              enabled: StatusEnum.ACTIVE,
              disabled: StatusEnum.SUSPENDED,
            }}
            variant={'primary'}
            buttonValue={{
              enable: 'activar',
              disable: 'suspender',
            }}
          />
        )}
      </div>
    </div>
  )
}

interface PublicProps {
  service: Service
  professional: User
  className?: string
  currency: {
    name: Currency['name']
    value: Currency['value']
  }
  params?: string
}

const ServiceCardPublic = ({
  service,
  professional,
  className,
  currency,
  params,
}: PublicProps) => {
  return (
    <div
      className={cn(
        'flex w-full flex-col gap-3 rounded-lg border-2 border-gray-300  bg-background p-4 sm:flex-row sm:gap-8',
        className,
      )}
    >
      {/* Left */}
      <div className="order-2 flex flex-col justify-between gap-4 sm:order-1">
        <div className="flex flex-col gap-3 whitespace-nowrap sm:w-[128px] sm:gap-4">
          {service.isVideoCall && (
            <Icon name="clock" size="lg" className="text-secondary">
              <span className="pl-2">{service.duration} minutos</span>
            </Icon>
          )}
          <Icon name="credit-card" size="lg" className="text-secondary">
            <Price
              amount={service.price * currency.value}
              currency={currency.name}
            ></Price>
          </Icon>
        </div>
        <div className="flex justify-center">
          <Link to={`/${professional.slug}/${service.slug}${params}`}>
            <ButtonTrigger>Reservar</ButtonTrigger>
          </Link>
        </div>
      </div>
      {/* Right */}
      <div className="order-1 flex flex-col gap-4 sm:order-2">
        <h3 className="text-h5">{service.name}</h3>
        <ScrollArea className="max-h-[100px]">
          <p>{service.description}</p>
        </ScrollArea>
      </div>
    </div>
  )
}

export const ServiceCard = ({
  className,
  variant = 'private',
  service,
  professional,
  currency,
  path,
  position,
  params,
}: {
  service?: Service
  professional?: User
  className?: string
  variant: 'public' | 'private'
  currency: {
    name: Currency['name']
    value: Currency['value']
  }
  path?: string
  position: {
    isFirst: boolean
    isLast: boolean
  }
  params?: string
}) => {
  return variant === 'private' ? (
    <ServiceCardPrivate
      className={className}
      service={service!}
      currency={currency}
      position={position}
    />
  ) : (
    <ServiceCardPublic
      professional={professional!}
      service={service!}
      className={className}
      currency={currency}
      params={params}
    />
  )
}
